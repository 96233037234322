














import { Component, Vue, Watch } from 'vue-property-decorator'
import HomeworkViewer from './components/HomeworkViewer.vue'
import { HomeworkController } from '@/services/request.service'

@Component({
  components: {
    HomeworkViewer,
  },
})
export default class HomeWork extends Vue {
  private get isChildRoute(): boolean {
    return this.$route.name !== 'homework'
  }
  private get menuList(): Array<any> {
    return [
      {
        key: 'courseClass',
        label: this.$t('homework.courseClassHomework'),
      },
      {
        key: 'CCAClass',
        label: this.$t('homework.CCAClassHomework'),
      },
    ]
  }

  private get type(): any {
    return this.$route.params.classType
  }

  @Watch('$route', { immediate: true })
  private onRouteChange(to): void {
    if (to.name === 'homework') {
      if (!to.params.classType) {
        this.$router
          .push({ name: 'homework', params: { classType: 'courseClass' } })
          .catch(err => {})
      } else {
        if (to.params.classType !== 'courseClass' && to.params.classType !== 'CCAClass') {
          this.$router.push({ name: 'notFound' })
        }
      }
    }
  }

  private changeType({ item, key, keyPath }): void {
    this.$router.push({ name: 'homework', params: { classType: key } }).catch(err => {})
  }
}
