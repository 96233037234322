












































































































































import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import { HomeworkController, DropDownController } from '@/services/request.service'
import moment from 'moment'
import FlexTooltip from '@/components/FlexTooltip.vue'
import { i18n } from '@/i18n/i18n'
import filterGroup from '@/components/filterGroup'
import { toPage } from '@/utils/utils'
import { createPagination } from '@/constant/constant'

@Component({
  components: {
    FlexTooltip,
    filterGroup,
  },
})
export default class HomeworkViewer extends Vue {
  @Prop() private readonly type!: String
  private loading: boolean = false
  private data: any = []
  private courses: Array<any> = []
  private schoolYearList: Array<any> = []
  private statuses: Array<any> = ['1021', '1022']
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]
  private filter: any = {
    courseId: 0,
    time: [],
    handInStatus: 0,
    handInOnline: 0,
    schoolYearId: undefined,
  }
  private statusList: any = ['1011', '1012', '1013']
  private pagination: any = createPagination({
    showTotal: total => i18n.t('common.pageTotal', { total }),
  })

  private get columns(): Array<any> {
    return [
      {
        key: 'name',
        dataIndex: 'name',
        title: this.$t('homework.name'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'name' },
      },
      this.type === 'courseClass'
        ? {
            dataIndex: 'subjectName',
            key: 'subjectName',
            ellipsis: true,
            title: this.$t('homework.subjectName'),
            scopedSlots: { customRender: 'subjectName' },
            align: 'left',
          }
        : -1,
      this.type === 'courseClass'
        ? {
            dataIndex: 'courseName',
            key: 'courseName',
            ellipsis: true,
            title: this.$t('homework.courseName'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          }
        : {
            dataIndex: 'courseName',
            key: 'CCAClassName',
            title: this.$t('homework.CCAClass'),
            scopedSlots: { customRender: 'courseName' },
            align: 'left',
          },
      {
        key: 'deadlineTime',
        title: this.$t('homework.deadlineTime'),
        align: 'left',
        width: 180,
        scopedSlots: { customRender: 'deadlineTime' },
      },
      {
        key: 'completeNum',
        title: this.$t('homework.completeStatus'),
        align: 'left',
        scopedSlots: { customRender: 'completeNum' },
      },
      {
        key: 'handInOnline',
        title: this.$t('homework.type'),
        align: 'left',
        ellipsis: true,
        scopedSlots: { customRender: 'handInOnline' },
      },
      {
        dataIndex: 'creatorName',
        key: 'creatorName',
        ellipsis: true,
        title: this.$t('homework.creatorName'),
        scopedSlots: { customRender: 'creatorName' },
        align: 'left',
      },
      {
        key: 'score',
        title: this.$t('homework.score'),
        align: 'right',
        width: 65,
        scopedSlots: { customRender: 'score' },
      },
      {
        key: 'operations',
        title: this.$t('common.operations'),
        align: 'left',
        width: 95,
        scopedSlots: { customRender: 'operations' },
      },
    ].filter(item => item !== -1)
  }

  @Watch('studentId', { immediate: true })
  public onStudentChange(newVal): void {
    if (newVal) {
      this.reset()
      this.getDropDownInfo()
    }
  }

  @Watch('type', { immediate: true })
  public onTypeChange(newVal): void {
    if (newVal) {
      this.reset()
      this.getDropDownInfo()
    }
  }

  private get studentId(): any {
    return this.$store.state.currentStudent?.studentId
      ? parseInt(this.$store.state.currentStudent.studentId, 10)
      : undefined
  }

  private get currentSchoolYearId(): any {
    return this.$store.state.currentSchoolYear.schoolYearId
  }

  private reset(): void {
    this.filter.courseId = []
    this.filter.time = []
    this.filter.handInStatus = 0
    this.filter.handInOnline = 0
    this.$set(this.pagination, 'current', 1)
    this.refresh()
  }

  private created() {
    this.getSchoolYearList()
  }

  private viewDetail(homeworkStudentId): void {
    //
    this.$router.push({
      path: 'homeworkDetail',
      name: 'homeworkDetail',
      query: { homeworkStudentId },
    })
  }

  private get isChildRoute(): boolean {
    return this.$route.name !== 'homework'
  }

  private getDropDownInfo(): void {
    const { schoolYearId } = this.filter
    if (!this.studentId || !schoolYearId) return
    let type = this.type === 'courseClass' ? '1001' : '1002'
    DropDownController.getRelatedCourses(this.studentId, type, schoolYearId, true)
      .then(res => {
        this.courses = res.data
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getSchoolYearList(): void {
    DropDownController.getSchoolYearList()
      .then(res => {
        this.schoolYearList = res.data
        this.filter.schoolYearId = (res.data[0] || {}).key
        // this.filter.schoolYearId = (res.data.filter(item => item.key === this.currentSchoolYearId)[0] || {}).key
        if (this.filter.schoolYearId) {
          this.getDropDownInfo()
        }
      })
      .finally(() => this.getData())
  }

  private changeYear(): void {
    this.$nextTick(() => {
      this.getDropDownInfo()
      this.getData()
    })
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(page = { current: 1, pageSize: this.pagination.defaultPageSize }): void {
    this.data = []
    this.loading = true
    const request = {
      pageCurrent: page.current,
      pageSize: page.pageSize,
      courseId: this.filter.courseId !== 0 ? this.filter.courseId : undefined,
      startTime: this.filter.time.length !== 0 ? moment(this.filter.time[0]).valueOf() : undefined,
      endTime: this.filter.time.length !== 0 ? moment(this.filter.time[1]).valueOf() : undefined,
      handInStatus: this.filter.handInStatus !== 0 ? this.filter.handInStatus : undefined,
      handInOnline: this.filter.handInOnline !== 0 ? this.filter.handInOnline : undefined,
      type: this.type === 'courseClass' ? '1041' : '1042',
      studentId: this.studentId,
      schoolYearId: this.filter.schoolYearId || undefined,
    }
    HomeworkController.getList(request)
      .then(res => {
        this.data = res.data.items.map(item => {
          return {
            ...item,
            deadlineTime: moment(item.endTime).format('YYYY-MM-DD'),
          }
        })
        this.pagination.total = res.data.totalItem
        this.pagination.current = page.current
        toPage(page, this.data, page => {
          this.pagination.current = page
          this.refresh()
        })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private handleTableChange(pagination): void {
    this.$set(this.pagination, 'current', pagination.current)
    this.$set(this.pagination, 'pageSize', pagination.pageSize)
    this.getData(pagination)
  }

  private refresh(): void {
    this.getData(this.pagination)
  }
}
