





































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { HomeworkController } from '@/services/request.service'
import moment from 'moment'
import { fileUploader } from '@/services/qiniu.service'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'highlight.js/styles/nord.css'

import hljs from 'highlight.js'
import { quillEditor } from 'vue-quill-editor'
import Quill from 'quill'
@Component({
  components: {
    quillEditor,
  },
})
export default class Diary extends Vue {
  private upoloadAttachmentLoading: boolean = false
  private saveDraftLoading: boolean = false
  private attachments: Array<any> = []
  private resources: Array<any> = []
  private saveLoading: boolean = false
  private InfoForm: any
  private homeworkStudentId: any
  private homeworkInfo: any = {}
  private moment = moment
  private statusList: any = ['1011', '1012', '1013']
  private scoreGradingList: Array<any> = [
    {
      key: 1,
      value: 'A',
    },
    {
      key: 2,
      value: 'B',
    },
    {
      key: 3,
      value: 'C',
    },
    {
      key: 4,
      value: 'D',
    },
    {
      key: 5,
      value: 'E',
    },
  ]
  private get editorOption(): any {
    return {
      theme: 'snow',
      modules: {
        toolbar: false,
        syntax: {
          highlieght: text => hljs.highlightAuto(text).value,
        },
      },
    }
  }
  private get infoList(): any {
    return [
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.sectionName'),
            value: this.homeworkInfo.sectionName,
          }
        : -1,
      this.type === 'courseClass'
        ? {
            label: this.$t('homework.subjectName'),
            value: this.homeworkInfo.subjectName,
          }
        : -1,
      {
        label: this.$t(`homework.${this.type}`),
        value: this.homeworkInfo.courseName,
      },
      {
        label: this.$t('homework.deadlineTime'),
        value: this.homeworkInfo.endTime
          ? moment(this.homeworkInfo.endTime).format('YYYY-MM-DD')
          : '',
      },
      {
        label: this.$t('homework.handInOnline'),
        value: this.homeworkInfo.handInOnline ? this.$t('common.true') : this.$t('common.false'),
      },
      {
        label: this.$t('homework.publishTime'),
        value: this.homeworkInfo.createTime
          ? moment(this.homeworkInfo.createTime).format('YYYY-MM-DD HH:mm')
          : '',
      },
    ].filter(item => item !== -1)
  }

  private beforeCreate(): void {
    this.InfoForm = this.$form.createForm(this)
  }

  private created(): void {
    this.$nextTick(() => {
      this.homeworkStudentId = this.$route.query.homeworkStudentId
      this.getData(this.homeworkStudentId)
    })
  }

  private get type(): any {
    return this.$route.params.classType
  }

  private removeFile(file): void {
    let index = this.attachments.indexOf(file)
    let tempList = this.attachments
    tempList.splice(index, 1)
    this.attachments = tempList
  }

  private customRequest({ file }): void {
    const index = file.name.lastIndexOf('.')
    const suffix = file.name.slice(index).toLowerCase()
    // 构造文件名
    const fileName = 'file_' + new Date().getTime() + suffix
    const suffixArray = [
      '.doc',
      '.docx',
      '.xls',
      '.xlsx',
      '.ppt',
      '.pptx',
      '.pdf',
      '.jpg',
      '.jpeg',
      '.png',
    ]
    if (suffixArray.includes(suffix)) {
      if (file.size <= 104857600) {
        this.upoloadAttachmentLoading = true
        fileUploader(file, fileName, 'file')
          .then(res => {
            let attachments = this.attachments
            if (this.attachments.length < 5) {
              attachments.push({
                uid: res.resourceId,
                url: process.env.VUE_APP_FILE_URL + res.key,
                name: file.name,
                status: 'done',
              })
            }
            this.attachments = attachments
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.upoloadAttachmentLoading = false
          })
      } else {
        this.$message.error(this.$tc('message.invalidFileSize'))
      }
    } else {
      this.$message.error(this.$tc('message.invalidFileFormat'))
    }
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private getData(homeworkStudentId): void {
    HomeworkController.detail(homeworkStudentId)
      .then(res => {
        this.homeworkInfo = res.data
        this.resources = res.data.resources
        this.attachments = res.data.handInResources.map(item => {
          return {
            uid: item.resourceId,
            url: item.resourceUrl,
            name: item.resourceName,
            status: 'done',
          }
        })
      })
      .catch(err => {
        console.error(err)
      })
  }
}
